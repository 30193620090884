import React, { useState, useEffect } from "react";
import config from "../config";
import axios from "axios";
import jwt_decode from "jwt-decode";

import { notification } from "antd";

const AuthContext = React.createContext({
  token: "",
  refreshToken: "",
  isLoggedIn: false,
  login: (token, refreshToken) => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialRefreshToken = localStorage.getItem("refreshToken");
  const [token, setToken] = useState(initialToken);
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken);

  const userIsLoggedIn = !!token;

  /* eslint-disable */
  useEffect(() => {
    const checkTokenValidity = () => {
      if (!token) {
        // No token present, set isLoggedIn to false
        setToken("");
        setRefreshToken("");
        return;
      }

      const decoded = jwt_decode(token);

      if (decoded.exp < Date.now() / 1000) {
        // Token is expired, attempt token refresh
        refreshAccessToken();
      }
    };

    checkTokenValidity();
  }, [token]);
  /* eslint-enable */

  const refreshAccessToken = () => {
    if (!refreshToken) {
      // No refresh token available, log out the user
      logoutUser();
      return;
    }

    axios
      .post(config.backendHost + "auth/refresh_token", {
        refresh_token: refreshToken,
      })
      .then((response) => {
        // Refresh token successful, update tokens
        const newToken = response.data.jwt;
        const newRefreshToken = response.data.refresh_jwt;
        setToken(newToken);
        setRefreshToken(newRefreshToken);
        localStorage.setItem("token", newToken);
        localStorage.setItem("refreshToken", newRefreshToken);
      })
      .catch(() => {
        // Refresh token failed, log out the user
        logoutUser();
      });
  };

  const logoutUser = () => {
    setToken("");
    setRefreshToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  };

  const loginHandler = (token, refreshToken) => {
    setToken(token);
    setRefreshToken(refreshToken);
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
  };

  /* eslint-disable */
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Return response if successful
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Token expired or unauthorized, attempt token refresh
          return refreshAccessToken()
            .then((response) => {
              // Retry original request with updated token
              error.config.headers.Authorization = `Bearer ${response.data.token}`;
              return axios.request(error.config);
            })
            .catch(() => {
              // Refresh token failed, log out user or perform other actions
              logoutUser();
            });
        } else {
          // Return other errors without modification
          return Promise.reject(error);
        }
      }
    );

    // Clean up interceptors on component unmount
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [token, refreshToken]);
  /* eslint-enable */

  const contextValue = {
    token: token,
    refreshToken: refreshToken,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
  };

  /* eslint-disable */
  useEffect(() => {
    const sessionTimeout = 55 * 60 * 1000; // 55 minutes in milliseconds
    let timeoutId = null;
    let broadcastChannel = null;

    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(logoutUser, sessionTimeout);
      timeoutId = newTimeoutId;
    };

    const handleUserActivity = () => {
      resetTimeout();
      if (broadcastChannel) {
        broadcastChannel.postMessage("activity");
      }
    };

    const handleBroadcastMessage = (event) => {
      if (event.data === "activity") {
        resetTimeout();
      }
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetTimeout();

    // Set up BroadcastChannel for inter-tab communication
    if (BroadcastChannel) {
      broadcastChannel = new BroadcastChannel("user_activity");
      broadcastChannel.addEventListener("message", handleBroadcastMessage);
    }

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(timeoutId);
      if (broadcastChannel) {
        broadcastChannel.removeEventListener("message", handleBroadcastMessage);
        broadcastChannel.close();
      }
    };
  }, [token, refreshToken]);
  /* eslint-enable */

  // log out after 24 hours
  useEffect(() => {
    if (token && token !== "") {
      const logoutAfter24Hours = () => {
        logoutUser();
      };

      const twentyFourHours = config.sessionTimeOut; // 24 hours in milliseconds
      const fiveMinutes = 5 * 60 * 1000; // 5 min before message

      const timeoutId = setTimeout(() => {
        notification.warning({
          message: "Session Ending Soon!",
          description:
            "Your session is about to end in the next five minutes. You will be redirected to the login page to continue.",
          duration: 60,
        });
      }, twentyFourHours - fiveMinutes);
      const logoutTimeoutId = setTimeout(logoutAfter24Hours, twentyFourHours);

      return () => {
        clearTimeout(timeoutId);
        clearTimeout(logoutTimeoutId);
      };
    }
  }, [token]);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
