import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import styled from "styled-components";

const StyledTable = styled((props) => <Table {...props} />)`
  && tbody > tr:hover > td {
    background: #e6f7ff;
  }
`;

const ModularTable = ({
  rowClassName,
  tableData,
  Columnheadings,
  onRowClick,
  rowSelect,
  pagination,
  loading,
  showHeader,
  style,
  handleTableChange,
  scroll,
  className
}) => {
  return (
    <StyledTable
      rowClassName={rowClassName}
      columns={Columnheadings}
      dataSource={tableData}
      filterMultiple={true}
      onRow={onRowClick}
      loading={loading}
      style={style}
      size="middle"
      rowSelection={rowSelect}
      pagination={pagination}
      showHeader={showHeader}
      onChange={(page, e, column, change) =>
        handleTableChange(page, e, column, change)
      }
      scroll={scroll}
      className={className}
    />
  );
};

export default ModularTable;
