import React, { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import config from "../../src/config/index";

import axios from "axios";

import resdata from "../data/ResData";

import LoadingWidget from "../components/LoadingWidget";

export const CustomerContex = createContext();

export const CustomerProvider = (props) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`${config.backendHost}customers/${id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          setIsLoading(false);
          res.deleted_addresses = [];
          res.deleted_contacts = [];
          res.deleted_address_serial_numbers = [];
          res.added_customer_notes = "";
          res.added_exchange_relationship_notes = "";
          res.customer_dba_name = null;
          res.scac = null;
          res.us_dot_number = null;
          res.golden_search_strings = null;

          function formatPhoneNumber(phoneNumber) {
            phoneNumber = phoneNumber?.toString().replace(/[^\d]/g, "");
            const formatted = `(${phoneNumber?.slice(
              0,
              3
            )}) ${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}`;
            return formatted;
          }

          res.customer_contacts.forEach((contact) => {
            if (contact.phone_number) {
              contact.phone_number = formatPhoneNumber(contact.phone_number);
            }
          });

          const formatZipCode = (value) => {
            if (!value) return value;
            let zipCode = value.replace(/-/g, "");
            const containsAlphabetRegex = /[A-Z]/g;

            if (zipCode.length > 3 && containsAlphabetRegex.test(zipCode)) {
              zipCode = `${zipCode.slice(0, 3)}-${zipCode.slice(3, 6)}`;
            }
            else if (zipCode.length > 5) {
              zipCode = `${zipCode.slice(0, 5)}-${zipCode.slice(5, 9)}`;
            }
            return zipCode;
          };

          res.customer_addresses.forEach((address) => {
            address.zip_code = formatZipCode(address.zip_code?.toString());
          });

          setData(res);
        })
        .catch(() => {
          setIsLoading(false);
          setData(resdata);
        });
    } else {
      setIsLoading(false);
      setData(resdata);
    }
  }, [id, token]);

  return (
    <>
      {!isLoading ? (
        <CustomerContex.Provider value={[data, setData]}>
          {props.children}
        </CustomerContex.Provider>
      ) : (
        <LoadingWidget />
      )}
    </>
  );
};
