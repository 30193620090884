import React, { useEffect, useState } from "react";

/* style */
import "./style.scss";

/* axios */
import axios from "axios";

/* config */
import config from "../../../../config";

/* antd */
import { Form, Input, Row, Col} from "antd";


const CustomerIdentity = ({ form }) => {
  const token = localStorage.getItem("token");

  const layout = {
    labelCol: { span: 12 },
    labelAlign: "left",
  };

  return (
    <Form colon={false} form={form} {...layout}>
      <Row>
        <Col span={10}>
          <Form.Item
            name="customer_name"
            label="Customer Name"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 12 }}
          >
            <Input disabled style={{marginLeft:"-2%"}}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="customer_id" label="CID"       labelCol={{ span: 9 }}
            wrapperCol={{ span: 12 }}>
            <Input style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="display_id" label="IID">
            <Input style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item
            name="interaction_date"
            label="Interaction Date"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 12 }}
          >
            <Input style={{ width: "100%", marginLeft:"-2%" }} disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="type" label="Interaction Type"       labelCol={{ span: 9 }}
            wrapperCol={{ span: 12 }}>
            <Input
              style={{
                width: "100%",
              }}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="mode" label="Mode">
            <Input
              style={{
                width: "100%",
              }}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name="title"
            label="Title"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
          >
            <Input name="title" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name="vantage_participants"
            label="Vantage Participants"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
          >
            <Input disabled mode="multiple" showArrow />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name="customer_participants"
            label="Customer Participants"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
          >
            <Input name="customer_participants" disabled />
          </Form.Item>
        </Col>
        <Col span={6}></Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name="summary"
            label="Discussion Summary"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea
              name="summary"
              disabled
              style={{ height: "100px" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}></Col>
      </Row>
    </Form>
  );
};

export default CustomerIdentity;
