import React, { useState, useEffect } from "react";
import SearchCustomerActions from "./SearchCustomerActions";
import Actions from "./Actions";
import EditInteractionActions from "./EditActions/EditInteractionActions";
import ActionItemModal from "./ActionItemModal";
import EditActionItemModal from "./EditActions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

/* style */
import "./style.scss";

import { CrownOutlined } from "@ant-design/icons";
import { Breadcrumb, Layout, Space } from "antd";
const { Header } = Layout;

const CustomerActionItems = ({ id, actionData }) => {
  const [searchValues, setSearchValues] = useState({});
  const [actionItemModel, setActionItemModal] = useState(false);
  const [actionEditItemModel, setEditActionItemModal] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [userName, setUserName] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setIsLoading] = useState(false);
  const { cid } = useParams();

  const handleSearch = ({ payload }) => {
    setSearchValues(payload);
    setIsFormSubmitting(true);
  };

  return (
    <React.Fragment>
      {id === undefined ? (
        <>
          <Header className="fixed-header">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CrownOutlined style={{ fontSize: "20px", color: "black" }} />
                <span
                  style={{
                    fontSize: "20px",
                    color: "black",
                    marginLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Customer Action Items
                </span>
                <div>
                  <Breadcrumb separator="" style={{ paddingTop: "5px" }}>
                    <Breadcrumb.Separator> </Breadcrumb.Separator>
                    <Breadcrumb.Item href="/customers">
                      Customer
                    </Breadcrumb.Item>
                    <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
                    {cid ? (
                      <>
                        <Breadcrumb.Item href="/customer-action-items">
                          Customer Action Items
                        </Breadcrumb.Item>
                        <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
                        <Breadcrumb.Item href={`/customer-action-items/${cid}`}>
                          Show Actions
                        </Breadcrumb.Item>
                      </>
                    ) : (
                      <Breadcrumb.Item href="/customer-action-items">
                        Customer Action Items
                      </Breadcrumb.Item>
                    )}
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </Header>
          <main
            className={
              cid !== undefined ? "main-container-cid" : "main-container"
            }
          >
            <Space direction="vertical" size={1}>
              <SearchCustomerActions
                handleSearch={handleSearch}
                isFormSubmitting={isFormSubmitting}
                cid={cid}
              />
              <Actions
                searchValues={searchValues}
                setIsFormSubmitting={setIsFormSubmitting}
                setActionItemModal={setActionItemModal}
                setEditActionItemModal={setEditActionItemModal}
                UserNameData={(user) => setUserName(user)}
                setRecordData={setRecordData}
                setSelectedId={setSelectedId}
                setIsLoading={setIsLoading}
                loading={loading}
                cid={cid}
              />
              <ActionItemModal
                actionItemModel={actionItemModel}
                setActionItemModal={setActionItemModal}
                userName={userName}
                setIsLoading={setIsLoading}
              />
              <EditActionItemModal
                actionEditItemModel={actionEditItemModel}
                setEditActionItemModal={setEditActionItemModal}
                userName={userName}
                selectedId={selectedId}
                setIsLoading={setIsLoading}
                recordData={recordData}
                setRecordData={setRecordData}
              />
            </Space>
          </main>
        </>
      ) : (
        <>
          <main className="main-container">
            <Space direction="vertical" size={1}>
              <EditInteractionActions
                searchValues={searchValues}
                actionData={actionData}
                setIsFormSubmitting={setIsFormSubmitting}
                setActionItemModal={setActionItemModal}
                setEditActionItemModal={setEditActionItemModal}
                UserNameData={(user) => setUserName(user)}
                setRecordData={setRecordData}
                setSelectedId={setSelectedId}
                id={id}
                loading={loading}
                setIsLoading={setIsLoading}
              />
              <ActionItemModal
                actionItemModel={actionItemModel}
                setActionItemModal={setActionItemModal}
                userName={userName}
                selectedId={selectedId}
                id={id}
                setIsLoading={setIsLoading}
              />
              <EditActionItemModal
                actionEditItemModel={actionEditItemModel}
                setEditActionItemModal={setEditActionItemModal}
                userName={userName}
                recordData={recordData}
                selectedId={selectedId}
                setIsLoading={setIsLoading}
              />
            </Space>
          </main>
        </>
      )}
    </React.Fragment>
  );
};
export default CustomerActionItems;
