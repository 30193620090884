import React from "react";
import PropTypes from "prop-types";
import Classnames from "classnames";
import { ThreeCircles, Oval, Bars, RotatingSquare } from "react-loader-spinner";

function LoadingWidget({
  type = "ThreeCircles",
  spinnerWidth = "100px",
  spinnerHeight = "100px",
  color = "#1890ff",
  className = "",
}) {
  let Loader;

  switch (type) {
    case "Oval":
      Loader = Oval;
      break;
    case "Bars":
      Loader = Bars;
      break;
    case "RotatingSquare":
      Loader = RotatingSquare;
      break;
    case "ThreeCircles":
    default:
      Loader = ThreeCircles;
      break;
  }

  return (
    <div
      className={Classnames("flex items-center justify-center h-screen", className)}
    >
      <Loader
        color={color}
        height={spinnerHeight}
        width={spinnerWidth}
        ariaLabel="loading-rotating"
      />
    </div>
  );
}

LoadingWidget.propTypes = {
  type: PropTypes.oneOf(["Oval", "Bars", "ThreeCircles", "RotatingSquare"]).isRequired,
  spinnerWidth: PropTypes.string,
  spinnerHeight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(LoadingWidget);
