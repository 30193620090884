import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import reportWebVitals from "./reportWebVitals";
/* Pages */
import Layout from "../src/pages/Layout";
/* Styles */
import "./index.scss";

/* Context API */
import { AuthContextProvider } from "./state/authContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <ConfigProvider>
      <Layout />
    </ConfigProvider>
  </AuthContextProvider>
);

reportWebVitals();
