const resdata = {
  client_partner: null,
  client_partner_vantage_poc_id: undefined,
  customer_addresses: [],
  customer_contacts: [],
  customer_created_by: "",
  customer_creation_date: [],
  customer_dba: "",
  customer_dot_numbers: "",
  customer_golden_search_strings: "",
  customer_id_act: undefined,
  customer_id: undefined,
  customer_legal_name: "",
  customer_lifecycle_status: "",
  customer_modification_date: [],
  customer_modified_by: "",
  customer_name: "",
  customer_notes: "",
  customer_owner: "",
  customer_record_status: "",
  customer_scac_codes: "",
  customer_secondary_businesses: [],
  dummy_customer: false,
  include_child_customers_events: false,
  duns_number: undefined,
  exchange_activity_status: "",
  exchange_relationship_notes: "",
  federal_tax_identification_number: undefined,
  hierarchy_status: "",
  lei_number: "",
  merged_into_customer_id: null,
  official_domain_name: "",
  parent_customer_id: undefined,
  participation_status: "",
  primary_business: "",
  record_enrichment_status: "",
  customer_tags: [],
  business_tags: [],
  my_tags: [],
  data_mastering_tags: [],
  address_type: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  country: "",
  zip_code: null,
  contact_name: "",
  department: "",
  designation: "",
  email_id: "",
  phone_number: undefined,
  address_number: undefined,
  last_month_par_act: "",
  last_month_par_val: undefined,
  three_month_par_act: "",
  three_month_par_val: undefined,
  six_month_par_act: "",
  six_month_par_val: undefined,
  last_month_non_par_act: "",
  last_month_non_par_val: undefined,
  three_month_non_par_act: "",
  three_month_non_par_val: undefined,
  six_month_non_par_act: "",
  six_month_non_par_val: undefined,
  created_by_admin_user_id: undefined,
  created_by_user_id: undefined,
  last_month_act: "",
  last_month_val: undefined,
  three_month_act: "",
  three_month_val: undefined,
  six_month_act: "",
  six_month_val: undefined,
  twelve_month_act: "",
  twelve_month_val: undefined,
  segment: "",
  sub_segment: ""
};

export default resdata;
