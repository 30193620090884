/* eslint-disable import/no-anonymous-default-export */

const config = {
    backendHost: `https://${process.env.REACT_APP_ENV}-backend.mdm.reach24.io/api/v1/`,
    sessionTimeOut: process.env.REACT_APP_SESSION_TIME_OUT
};

export default {
    ...config
};
