import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Select,
  notification,
  DatePicker,
  Modal,
  Form,
  Button,
  Space,
  Popover,
} from "antd";
import axios from "axios";
import config from "../../../config";
import moment from "moment";
import { validateTwoChar } from "../../../utils/validation/validateTwoChar";
import LoadingWidget from "../../../components/LoadingWidget";

//icons
import { HistoryOutlined } from "@ant-design/icons";
import "./style.scss";

const EditActionItemModal = ({
  actionEditItemModel,
  setEditActionItemModal,
  userName,
  selectedId,
  setIsLoading,
  recordData,
  setRecordData,
}) => {
  const [actionOwners, setActionOwners] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerIDList, setCustomerIDList] = useState([]);
  const [IIDList, setIIDList] = useState([]);
  const [createActionForm] = Form.useForm();
  const token = localStorage.getItem("token");
  const formRef = useRef();
  const [actionData, setActionData] = useState(null);
  const statusList = ["Open", "Delayed", "Closed"];
  const layout = {
    labelCol: { span: 7 },
    labelAlign: "left",
    wrapperCol: { span: 15 },
    gutter: 24,
  };
  const [isActionOwnerModalVisible, setIsActionOwnerModalVisible] =
    useState(false);
  const [isDueDateModalVisible, setIsDueDateModalVisible] = useState(false);
  const [isStatusUpdateModalVisible, setIsStatusUpdateModalVisible] =
    useState(false);
    const duebuttonRef = useRef(null);
    const ownerbuttonRef = useRef(null);
    const statusbuttonRef = useRef(null);

  const ESTDate = () => {
    const localDate = new Date();
    const utcTime = localDate.getTime() + localDate.getTimezoneOffset() * 60000;
    const estOffset = -5 * 60 * 60 * 1000;
    const estDate = new Date(utcTime + estOffset);
    const year = estDate.getFullYear();
    const month = String(estDate.getMonth() + 1).padStart(2, "0");
    const day = String(estDate.getDate()).padStart(2, "0");
    const hours = String(estDate.getHours()).padStart(2, "0");
    const minutes = String(estDate.getMinutes()).padStart(2, "0");
    const seconds = String(estDate.getSeconds()).padStart(2, "0");
    const estDateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return estDateTimeString;
  };

  const showActionOwnerModal = () => {
    setIsActionOwnerModalVisible(true);
    if (ownerbuttonRef.current) {
      ownerbuttonRef.current.blur();
    }
  };

  const showDueDateModal = () => {
  
    setIsDueDateModalVisible(true);
    if (duebuttonRef.current) {
      duebuttonRef.current.blur();
    }
  };

  const showStatusUpdateModal = () => {
    setIsStatusUpdateModalVisible(true);
    if (statusbuttonRef.current) {
      statusbuttonRef.current.blur();
    }
  };

  useEffect(() => {
    if (!selectedId) return;

    const fetchData = async () => {
      createActionForm.resetFields();
      setActionData(null);

      if (recordData) {
        // Set new data
        setActionData(recordData);
        createActionForm.setFieldsValue(recordData);
      }

      try {
        const actionResponse = await axios.get(
          `${config.backendHost}actions/${selectedId}`,
          {
            headers: { authorization: token },
          }
        );

        setCustomerList(
          actionResponse.data.data?.map((field) => ({
            value: field.customer_name,
            label: field.customer_name,
          }))
        );
        setCustomerIDList(
          actionResponse.data.data?.map((field) => ({
            value: field.customer_id,
            label: field.customer_id,
          }))
        );
        setIIDList(
          actionResponse.data?.map((id) => ({
            value: id.id,
            label: id.display_id,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [selectedId, recordData, token]);

  useEffect(() => {
    // setActionData(recordData);
    // createActionForm.setFieldsValue(recordData);
    axios
      .get(`${config.backendHost}interactions/users/`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setActionOwners(
          response.data?.map((users) => {
            return {
              value: users,
              label: users,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedId, recordData]);

  const handleEditActions = () => {
    createActionForm
      .validateFields()
      .then(() => {
        axios.put(
          `${config.backendHost}actions/${selectedId}`,
          {
            name: createActionForm.getFieldValue("name"),
            customer_id: createActionForm.getFieldValue("customer_id"),
            interaction_id: actionData.interaction_id,
            due_date: createActionForm.getFieldValue("due_date"),
            prev_due_dates: createActionForm.getFieldValue("prev_due_dates"),
            action_desc: createActionForm.getFieldValue("action_desc"),
            status_desc: createActionForm.getFieldValue("status_desc"),
            action_owner: createActionForm.getFieldValue("action_owner"),
            status: createActionForm.getFieldValue("status"),
          },
          {
            headers: { authorization: token },
          }
        );
      })
      .then(() => {
        setEditActionItemModal(false);
        createActionForm.resetFields();
        notification.open({
          message: `Success`,
          description: "Action Updated",
          className: "custom-class",
          style: { width: 400 },
        });
        // localStorage.setItem("collapsable", true);
        setIsLoading(true);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!actionData) {
    return null;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.ctrlKey) {
      handleEditActions();
    }
    if (event.key === "Escape") {
      handleCancel();
      setRecordData([]);
      createActionForm.resetFields();
      setEditActionItemModal(false);
      setIsLoading(true);
    }
  };

  const handleOk = () => {
    setIsActionOwnerModalVisible(false);
    setIsDueDateModalVisible(false);
    setIsStatusUpdateModalVisible(false);
  };

  const handleCancel = () => {
    setIsActionOwnerModalVisible(false);
    setIsDueDateModalVisible(false);
    setIsStatusUpdateModalVisible(false);
  };

  return (
    <React.Fragment>
      {actionData ? (
        <Modal
          open={actionEditItemModel}
          title={`View/Edit Action Item (${createActionForm.getFieldValue("actions_display_id")})`}
          width={1000}
          footer={[
            <Button
              key="cancel"
              onClick={() => {
                createActionForm.resetFields();
                setIsLoading(true);
    
                setEditActionItemModal(false);
              }}
            >
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleEditActions}>
              OK
            </Button>,
          ]}
          onCancel={() => {
            createActionForm.resetFields();
            setIsLoading(true);

            setEditActionItemModal(false);
          }}
        >
          <Form
            ref={formRef}
            colon={false}
            form={createActionForm}
            {...layout}
            onFinish={handleEditActions}
            onKeyDown={handleKeyDown}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "24px",
              }}
            >
              <div>
                <Form.Item
                  name="display_id"
                  label="Interaction ID"
                  initialValue={actionData ? actionData.display_id : ""}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item name="customer_id" label="CID">
                  <Input
                    readOnly
                    placeholder="Select Customer Name"
                    style={{ width: "100%" }}
                    onChange={(value, options) => {
                      createActionForm.setFieldValue("customer_id", value);
                    }}
                    options={customerIDList}
                  />
                </Form.Item>
                <Form.Item
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Customer Name!",
                    },
                  ]}
                  label="Customer Name"
                >
                  <Input
                    readOnly
                    placeholder="Select Customer Name"
                    style={{ width: "100%" }}
                    onChange={(value, options) => {
                      createActionForm.setFieldValue("customer_name", value);
                    }}
                    options={customerList}
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Action Item Name!",
                    },
                    { validator: validateTwoChar },
                  ]}
                  label="Action Item Name"
                >
                  <Input
                    type="text"
                    placeholder="Please enter Action Item Name"
                    onChange={(e) =>
                      createActionForm.setFieldValue("name", e.target.value)
                    }
                  />
                </Form.Item>
                <div style={{ display: "-webkit-box", alignItems: "center" }}>
                  <Form.Item
                    name="action_owner"
                    rules={[
                      {
                        required: true,
                        message: "Please enter action owner!",
                      },
                    ]}
                    label="Action Owner"
                    style={{ width: "calc(100% - 32px)" }}
                  >
                    <Select
                      showArrow
                      allowClear
                      placeholder="Select Action Owner"
                      style={{ width: "calc(111% - 13px)", marginLeft: "4%" }}
                      onChange={(value, options) => {
                        createActionForm.setFieldValue("action_owner", value);
                      }}
                      options={actionOwners}
                    />
                  </Form.Item>

                  <Button
                    icon={<HistoryOutlined />}
                    style={{ marginLeft: "-23%" }}
                    onClick={showActionOwnerModal}
                    ref={ownerbuttonRef}
                  />
                  <Modal
                    title="Previous Action Owners"
                    open={isActionOwnerModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        Close
                      </Button>,
                    ]}
                  >
                    <Input.TextArea
                      value={
                        actionData.prev_action_owners
                          ? actionData.prev_action_owners.replace(/\n/g, "\n")
                          : ""
                      }
                      style={{ width: "100%", resize: "none", height: "150px" }}
                      readOnly
                    />
                  </Modal>
                </div>
                <div style={{ display: "-webkit-box", alignItems: "center" }}>
                  <Form.Item
                    name="due_date"
                    label="Due Date"
                    valuePropName={"date"}
                    initialValues=""
                    rules={[
                      { required: true, message: "Due Date is required" },
                    ]}
                    style={{ width: "calc(100% - 32px)" }}
                  >
                    <Space direction="vertical">
                      <DatePicker
                        defaultValue={moment(
                          createActionForm.getFieldValue("due_date"),
                          "MM-DD-YYYY"
                        )}
                        format={"MM-DD-YYYY"}
                        style={{ width: "calc(111% - 13px)", marginLeft: "4%" }}
                        onChange={(date, dateString) => {
                          createActionForm.setFieldsValue({
                            due_date: dateString,
                          });
                        }}
                      />
                    </Space>
                  </Form.Item>

                  <Button
                    icon={<HistoryOutlined />}
                    style={{ marginLeft: "-23%" }}
                    onClick={showDueDateModal}
                    ref={duebuttonRef}
                  />
                  <Modal
                    title="Previous Due Dates"
                    open={isDueDateModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        Close
                      </Button>,
                    ]}
                  >
                    <Input.TextArea
                      value={
                        actionData.prev_due_dates
                          ? actionData.prev_due_dates.replace(/\n/g, "\n")
                          : ""
                      }
                      style={{ width: "100%", resize: "none", height: "150px" }}
                      readOnly
                    />
                  </Modal>
                </div>
              </div>

              <div>
                <Form.Item
                  name="action_desc"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Action Item Description!",
                    },
                    { validator: validateTwoChar },
                  ]}
                  label="Action Item Desc"
                >
                  <Input.TextArea
                    type="text"
                    placeholder="Please enter Action Item Description"
                    onChange={(e) =>
                      createActionForm.setFieldValue(
                        "action_desc",
                        e.target.value
                      )
                    }
                    style={{ minHeight: 115 }}
                  />
                </Form.Item>
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Please enter status!",
                    },
                  ]}
                  label="Status"
                >
                  <Select
                    showArrow
                    allowClear
                    placeholder="Select Status"
                    style={{ width: "100%" }}
                    onChange={(value, options) => {
                      createActionForm.setFieldValue("status", value);
                    }}
                    options={statusList.map((status) => {
                      return {
                        value: status,
                        label: status,
                      };
                    })}
                  />
                </Form.Item>
                <div style={{ display: "-webkit-box", alignItems: "center" }}>
                  <Form.Item
                    name="status_desc"
                    label="Status Update"
                    style={{ width: "calc(100% - 32px)" }}
                  >
                    <Input.TextArea
                      type="text"
                      placeholder="Please enter Status Update"
                      onChange={(e) =>
                        createActionForm.setFieldValue(
                          "status_update",
                          e.target.value
                        )
                      }
                      className="status-text"
                      style={{
                        marginRight: "1px",
                        minHeight: 115,
                        width: "calc(120% - 13px)",
                        marginLeft: "4%",
                      }}
                    />
                  </Form.Item>

                  <Button
                    icon={<HistoryOutlined />}
                    onClick={showStatusUpdateModal}
                    style={{ marginLeft: "-15%" }}
                    ref={statusbuttonRef}
                  />
                  <Modal
                    title="Previous Status Descriptions"
                    open={isStatusUpdateModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        Close
                      </Button>,
                    ]}
                  >
                    <Input.TextArea
                      value={
                        actionData.prev_status_desc
                          ? actionData.prev_status_desc.replace(/\n/g, "\n")
                          : ""
                      }
                      style={{ width: "100%", resize: "none", height: "150px" }}
                      readOnly
                    />
                  </Modal>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      ) : (
        <LoadingWidget />
      )}
    </React.Fragment>
  );
};

export default EditActionItemModal;
