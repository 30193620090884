import React, { useEffect, useState } from "react";

/* antd */
import { Button, Form, Breadcrumb, Collapse, Space, Layout,notification } from "antd";

/* axios */
import axios from "axios";

/* config */
import config from "../../../config";

/* antd icons */
import { CrownOutlined } from "@ant-design/icons";


/* components */
import LoadingWidget from "../../../components/LoadingWidget";

/* style */
import CustomerIdentity from "./components/CustomerIdentity";
import './style.scss'

/* utils */
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useChangeRoute } from "../../../utils/routes";
import CustomerActionItems from "../../Customer Action Items";

const ViewInteraction = () => {
  const { Header } = Layout;
  // loading data
  const [isLoading, setIsLoading] = useState(false);
  // modal state
  const { Panel } = Collapse;

  /* eslint-disable */
  const [form] = Form.useForm();
  const [actionData,setActionData]= useState([])

  const token = localStorage.getItem("token");
  const { id } = useParams();
  const collapsableItem= localStorage.getItem("collapsable")
  const { changeRoutes } = useChangeRoute();
  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true)
    axios
      .get(`${config.backendHost}interactions/${id}`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setIsLoading(false)
        setActionData(response.data.data.actions);
        form.setFieldsValue(response.data.data);
        const formattedParticipants = response.data.data.vantage_participants.join(', ');
        form.setFieldValue('vantage_participants',formattedParticipants)
        const customerParticipants = response.data.data.customer_participants.join(', ');
        form.setFieldValue('customer_participants',customerParticipants)
      });
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const handleSendEmail = () => {
    setIsLoading(true)
    axios
      .post(
        `${config.backendHost}interactions/send_mail/${id}`,
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((response) => {
        setIsLoading(false)
        notification.open({
          message: `Success`,
          description: response.data,
          className: "custom-class",
          style: {
            width: 400,
          },
        });     
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

    fetchAuditLogs();
  };

  return (
    <>
      <Header className="sticky-header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CrownOutlined style={{ fontSize: "20px", color: "black" }} />
            <span
              style={{
                fontSize: "20px",
                color: "black",
                marginLeft: "10px",
                fontWeight: "bold",
              }}
            >
              Customer Interactions
            </span>
            <div>
              <Breadcrumb separator="" style={{ paddingTop: "5px" }}>
                <Breadcrumb.Separator> </Breadcrumb.Separator>
                <Breadcrumb.Item href="/customers">Customer</Breadcrumb.Item>
                <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
                <Breadcrumb.Item href="/customer-interactions">
                  Customer Interactions
                </Breadcrumb.Item>
                <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
                <Breadcrumb.Item href={`/customer-interactions/view-interactions/${id}`}>
                  View Interactions
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div style={{ marginRight: "8px" }}>
                  <Button type="primary" onClick={handleSendEmail}>Send as Email</Button>              </div>
        </div>
      </Header>
      <>
        {!isLoading ? (
          <><main className="main-container">
            <Collapse defaultActiveKey={1} className="collapse-item" onChange={()=>{
            }}>
              <Panel
                header={
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <h3 style={{ fontSize: "20px" }}>Interaction Summary</h3>
                  </div>
                  <div className="button-container">
                    <Button type="primary"  onClick={() => {
                      changeRoutes(`/customer-interactions/edit-interactions/${id}`);
                    }} style={{width: "120px"}}>
                      Edit
                    </Button>
                  </div>
                </div>                          
              
              }
                key="1"
              >
                <CustomerIdentity form={form} />
              </Panel>
            </Collapse>
            <Space direction="vertical" size={2}></Space>
          </main><CustomerActionItems id={id} actionData={actionData} /></>  
        ) : (
          <LoadingWidget />
        )}
      </>
    </>
  );
};

export default ViewInteraction;
