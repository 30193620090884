import React, { useState, useEffect } from "react";

/* antd */
import "antd/dist/antd.css";
import {
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
  Space,
  Collapse,
  PageHeader,
} from "antd";

import axios from "axios";

import config from "../../config";

/* style */
import "./style.scss";
import { CrownOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const SearchCustomerActions = ({ handleSearch, isFormSubmitting, cid }) => {
  const [customerName, setCustomerName] = useState("");
  const [displayID, setDisplayID] = useState("");
  const [customerID, setCustomerID] = useState(null);
  const [dueDate, setDueDate] = useState([]);
  const [actionName, setActionName] = useState("");
  const [actionDesc, setActionDesc] = useState("");
  const [statusDesc, setStatusDesc] = useState("");
  const [status, setStatus] = useState([]);
  const [raisedAt, setRaisedAt] = useState([]);
  const [actionOwner, setActionOwner] = useState("");
  const [owners, setOwners] = useState([]);
  const [raisedBy, setRaisedBy] = useState("");
  const [form] = Form.useForm();
  const [customerNames, setCustomerNames] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [selectedCustomerIdOption, setSelectedCustomerIdOption] =
    useState("equal_to");
  const [IIDList, setIIDList] = useState([]);
  const [interactionIDList, setInteractionIDList] = useState([]);

  const [interactionID, setInteractionID] = useState("");

  const [actionList, setActionList] = useState([]);

  const statusList = ["Open", "Delayed", "Closed"];

  const token = localStorage.getItem("token");
  const { RangePicker } = DatePicker;

  const layout = {
    labelCol: { span: 12 },
    labelAlign: "left",
    wrapperCol: { span: 20 },
    gutter: 16,
  };

  const handleSubmit = () => {
    const payload = {
      display_id: displayID,
      customer_id: selectedCustomerIds(),
      customer_id_action: selectedCustomerIds()
        ? selectedCustomerIdOption
        : undefined,
      customer_name: customerName,
      due_date: dueDate,
      name: actionName,
      action_desc: actionDesc,
      status_desc: statusDesc,
      action_owner: actionOwner,
      status: status,
      raised_at: raisedAt,
      raised_by: raisedBy,
      interaction_id: interactionID,
    };
    handleSearch({ payload });
  };

  const selectedCustomerIds = () => {
    if (
      selectedCustomerIdOption === "between" &&
      !!form.getFieldValue("customer_id_from") &&
      !!form.getFieldValue("customer_id_to")
    ) {
      return [
        Number(form.getFieldValue("customer_id_from")),
        Number(form.getFieldValue("customer_id_to")),
      ];
    } else if (!!form.getFieldValue("customer_id")) {
      return [Number(form.getFieldValue("customer_id"))];
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (cid) {
      axios
        .get(config.backendHost + `customers/search/?customer_id=${cid}`, {
          headers: {
            "Content-type": "application/json",
            authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.data.map((res) => ({
            customer_id: res.customer_id,
            customer_name: res.customer_name,
          }));
          setCustomerName(data[0].customer_name);
          setCustomerID(data[0].customer_id);
          form.setFieldsValue({ customer_name: data[0].customer_name });
          form.setFieldsValue({ customer_id: data[0].customer_id });
        })
        .catch((error) => {
          // Handle error
          console.log(error);
        });
    }

    axios
      .get(`${config.backendHost}tenants/remap_dropdown/?order_by=name`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        const customers = response.data.data;
        setCustomerNames(
          customers?.map((customer) => {
            return {
              value: customer.customer_name,
              label: customer.customer_name,
            };
          })
        );
        setCustomerLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
    axios
      .get(`${config.backendHost}actions/ids/`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setActionList(
          response.data?.map((id) => {
            return {
              value: id.display_id,
              label: id.display_id,
            };
          })
        );
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
    axios
      .get(`${config.backendHost}interactions/ids/`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setIIDList(
          response.data?.map((id) => {
            return {
              value: id.display_id,
              label: id.display_id,
              customer_name: id.customer_name,
              customer_id: id.customer_id,
            };
          })
        );
        setInteractionIDList(
          response.data?.map((id) => {
            return {
              value: id.id,
              label: id.display_id,
              customer_name: id.customer_name,
              customer_id: id.customer_id,
            };
          })
        );
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
    axios
      .get(`${config.backendHost}interactions/users/`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setOwners(
          response.data?.map((type) => {
            return {
              value: type,
              label: type,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {cid !== undefined ? (
        <>
          <PageHeader
            className="site-page-header"
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CrownOutlined
                  className="customer-icon"
                  style={{
                    fontSize: "20px",
                    color: "#1890ff",
                    marginLeft: "2px",
                  }}
                />
                <h4 className="header-title">{customerName + ` (${cid})`}</h4>
              </div>
            }
          />
          <Collapse
            defaultActiveKey={["1"]}
            style={{ marginTop: "10px" }}
            className={"search-box search-page"}
          >
            <Panel header={<p style={{ fontSize: "20px" }}>Search</p>} key="1">
              {" "}
              <Form
                form={form}
                colon={false}
                onFinish={handleSubmit}
                {...layout}
              >
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Customer Name"
                      name="customer_name"
                    >
                      <Input allowClear style={{ width: "100%" }} readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Customer ID"
                      name="customer_id"
                    >
                      <Input style={{ width: "100%" }} readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action ID"
                      name="display_id"
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Select
                          showArrow
                          allowClear
                          showSearch
                          placeholder="Enter Action ID"
                          onChange={(value, options) => {
                            setDisplayID(value);
                          }}
                          options={actionList}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Due Date"
                      name="due_date"
                    >
                      <Space direction="vertical">
                        <RangePicker
                          format={"MM-DD-YYYY"}
                          onChange={(date, dateString) => {
                            setDueDate(dateString);
                          }}
                          style={{ width: "100%" }}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action Item Name"
                      name="name"
                    >
                      <Input
                        name="name"
                        type="text"
                        placeholder="Enter Action Item Name"
                        onChange={(event) => setActionName(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action Item Description"
                      name="action_desc"
                    >
                      <Input
                        name="action_desc"
                        type="text"
                        placeholder="Enter Action Desc"
                        onChange={(event) => setActionDesc(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Status Description"
                      name="status_desc"
                    >
                      <Input
                        name="status_desc"
                        type="text"
                        placeholder="Enter Status Desc"
                        onChange={(event) => setStatusDesc(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action Owner"
                      name="action_owner"
                    >
                      <Select
                        showArrow
                        allowClear
                        showSearch
                        placeholder="Select Action Owner"
                        style={{ width: "100%" }}
                        onChange={(value, options) => {
                          setActionOwner(value);
                        }}
                        options={owners}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Raised By"
                      name="raised_by"
                    >
                      <Select
                        showArrow
                        allowClear
                        placeholder="Select Raised by"
                        options={owners}
                        onChange={(value, options) => {
                          setRaisedBy(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Raised At"
                      name="raised_at"
                    >
                      <Space direction="vertical">
                        <RangePicker
                          format={"MM-DD-YYYY"}
                          onChange={(date, dateString) => {
                            setRaisedAt(dateString);
                          }}
                          style={{ width: "100%" }}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Interaction ID"
                      name="interactions_id"
                    >
                      <Select
                        showArrow
                        allowClear
                        showSearch
                        placeholder="Select Interaction ID"
                        style={{ width: "100%" }}
                        onChange={(value, options) => {
                          setInteractionID(value);
                        }}
                        options={interactionIDList}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isFormSubmitting}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </>
      ) : (
        <>
          <Collapse
            defaultActiveKey={["1"]}
            style={{ marginTop: "50px" }}
            className={"search-box search-page"}
          >
            <Panel header={<p style={{ fontSize: "20px" }}>Search</p>} key="1">
              {" "}
              <Form
                form={form}
                colon={false}
                onFinish={handleSubmit}
                {...layout}
              >
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Customer Name"
                      name="customer_name"
                    >
                      <Input
                        allowClear
                        placeholder="Enter customer name"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setCustomerName(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Customer ID"
                      name="customer_id"
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Select
                          placeholder="Select customer name"
                          style={{
                            width:
                              selectedCustomerIdOption === "between"
                                ? "40%"
                                : "50%",
                          }}
                          onChange={(value) =>
                            setSelectedCustomerIdOption(value)
                          }
                          options={[
                            { value: "equal_to", label: "Equal to" },
                            { value: "lesser_than", label: "Less than" },
                            { value: "greater_than", label: "Greater than" },
                            { value: "between", label: "Between" },
                          ]}
                          defaultValue={selectedCustomerIdOption}
                        />
                        {selectedCustomerIdOption === "between" ? (
                          <Space.Compact>
                            <Input
                              name="customer_id_from"
                              style={{ width: "49%" }}
                              placeholder="From"
                              onChange={(event) =>
                                form.setFieldValue(
                                  "customer_id_from",
                                  event.target.value
                                )
                              }
                            />
                            <Input
                              name="customer_id_to"
                              style={{ width: "51%" }}
                              placeholder="To"
                              onChange={(event) =>
                                form.setFieldValue(
                                  "customer_id_to",
                                  event.target.value
                                )
                              }
                            />
                          </Space.Compact>
                        ) : (
                          <Input
                            style={{ width: "50%" }}
                            placeholder="Enter value"
                            onChange={(event) =>
                              setCustomerID(event.target.value)
                            }
                          />
                        )}
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action ID"
                      name="display_id"
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Select
                          showArrow
                          allowClear
                          showSearch
                          placeholder="Enter Action ID"
                          onChange={(value, options) => {
                            setDisplayID(value);
                          }}
                          options={actionList}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Due Date"
                      name="due_date"
                    >
                      <Space direction="vertical">
                        <RangePicker
                          format={"MM-DD-YYYY"}
                          onChange={(date, dateString) => {
                            setDueDate(dateString);
                          }}
                          style={{ width: "100%" }}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action Item Name"
                      name="name"
                    >
                      <Input
                        name="name"
                        type="text"
                        placeholder="Enter Action Item Name"
                        onChange={(event) => setActionName(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action Item Description"
                      name="action_desc"
                    >
                      <Input
                        name="action_desc"
                        type="text"
                        placeholder="Enter Action Desc"
                        onChange={(event) => setActionDesc(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Status Description"
                      name="status_desc"
                    >
                      <Input
                        name="status_desc"
                        type="text"
                        placeholder="Enter Status Desc"
                        onChange={(event) => setStatusDesc(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Action Owner"
                      name="action_owner"
                    >
                      <Select
                        showArrow
                        allowClear
                        showSearch
                        placeholder="Select Action Owner"
                        style={{ width: "100%" }}
                        onChange={(value, options) => {
                          setActionOwner(value);
                        }}
                        options={owners}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Raised By"
                      name="raised_by"
                    >
                      <Select
                        showArrow
                        allowClear
                        placeholder="Select Raised by"
                        options={owners}
                        onChange={(value, options) => {
                          setRaisedBy(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="search-child-row">
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Raised Date"
                      name="raised_at"
                    >
                      <Space direction="vertical">
                        <RangePicker
                          format={"MM-DD-YYYY"}
                          onChange={(date, dateString) => {
                            setRaisedAt(dateString);
                          }}
                          style={{ width: "100%" }}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Form.Item
                      wrapperCol={{ span: 16 }}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      label="Interaction ID"
                      name="interactions_id"
                    >
                      <Select
                        showArrow
                        allowClear
                        showSearch
                        placeholder="Select Interaction ID"
                        style={{ width: "100%" }}
                        onChange={(value, options) => {
                          setInteractionID(value);
                        }}
                        options={interactionIDList}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} className="search-column">
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isFormSubmitting}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </>
      )}
    </>
  );
};

export default SearchCustomerActions;
