import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
/* antd */
import { Checkbox, Input, Card, notification, Button, Modal, Form, Tooltip } from "antd";

import "antd/dist/antd.css";

/* style */
import "./style.scss";

/* axios */
import axios from "axios";
import config from "../../config";

/* Global Component */
import ModularTable from "../../components/ModularTable";
import TableLoading from "../../components/TableLoading";

const { TextArea } = Input;

const Actions = ({
  searchValues,
  setIsFormSubmitting,
  setActionItemModal,
  UserNameData,
  setRecordData,
  setSelectedId,
  setEditActionItemModal,
  setIsLoading,
  loading,
  cid
}) => {
  const token = localStorage.getItem("token");
  const [Loading, setLoading] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [deleteComments, setDeleteComments] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [Actions, setActions] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [sort, setSort] = useState({
    order_by: "",
    asc_or_desc: "",
  });
  const [currentCustomers, setCurrentCustomers] = useState([]);
  const [unselectedCustomers, setUnselectedCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [statusFilter, setStatusFilter] = useState(['Open','Delayed']);
  const [deleteForm] = Form.useForm(); 

  function ActionDate(date) {
    var comp = date.split("-");
    var convertedDate = comp[1] + "-" + comp[2] + "-" + comp[0];
    return convertedDate;
  }

  function handleSelectedRecord(record) {
    setLoading(true);
    setIsLoading(false);
    setSelectedId(record.id);
    axios
      .get(`${config.backendHost}actions/${record.id}`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setLoading(false);
        setRecordData(response.data.data);
        setEditActionItemModal(true); // Open modal after data is fetched
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function RaisedDate(date) {
    var dateComponents = date.substring(0, 10).split("-");

    // Format the date as mm-dd-yyyy HH:MM:SS
    var formattedDate =
      dateComponents[1] +
      "-" +
      dateComponents[2] +
      "-" +
      dateComponents[0] 

    return formattedDate;
  }

  const handleDeleteActionItem = () => {
    const deletedIds = selectedRows.map((row) => row.id);
    setLoading(true);
    axios
      .put(
        `${config.backendHost}actions/delete/`,
        {
          ids: deletedIds,
          comments: deleteMessage,
        },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((response) => {
        deleteForm.resetFields();
        setDeleteComments(false);
        setLoading(false);
        notification.open({
          message: `Success`,
          description: response.data,
          className: "custom-class",
          style: {
            width: 400,
          },
        });
        setIsLoading(true)
     
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    fetchAuditLogs();
  };

  const onChangePagination = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(true);
    const payload = searchValues;
    axios
      .post(
        `${config.backendHost}actions/search/?page=${page.current}&per_page=${page.pageSize}&order_by=${sort.order_by}&asc_or_desc=${sort.asc_or_desc}`,
        {
          display_id: payload.display_id,
          customer_id: cid ? cid : payload.customer_id,
          customer_id_action: payload.customer_id_action,
          customer_name: payload.customer_name,
          due_date: payload.due_date,
          action_desc: payload.action_desc,
          status_desc: payload.status_desc,
          name:payload.name,
          action_owner: payload.action_owner,
          status: statusFilter,
          raised_at: payload.raised_at,
          raised_by: payload.raised_by,
          interaction_display_id: payload.interaction_display_id,
          interaction_id: payload.interaction_id

        },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((response) => {
        const res = response.data;
        setActions(res.data);
        setPagination({
          ...pagination,
          current: response.data.meta_data.page,
          pageSize: response.data.meta_data.per_page,
          total: response.data.meta_data.count,
        });
        const actionID = response.data.data.map((actions) => actions.id);
        setCurrentCustomers(actionID);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowSelection = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    onChange: handleRowSelection,
    type: "checkbox",
  };

  const handleTableChange = (page,filter, e, column, change) => {

    if (column.action === "sort") {
      setSort({
        order_by: e.order === undefined ? "" : e.field,
        asc_or_desc:
          e.order === "ascend"
            ? "asc"
            : e.order === "descend"
            ? "desc"
            : "",
      });
    }
    if (column.action === 'filter') {
      if (!filter.status) {
        setStatusFilter('')
        fetchLogs(filter.status)
      } 
      else {
        setStatusFilter(filter.status)
        fetchLogs(filter.status)
      }
    }
 
    if (column.action === "paginate") {
      onChangePagination(page);
    }
  };

  /* Custom pagination */
  const customPagination = {
    ...pagination,
    showSizeChanger: pagination.total > 10,
    showTotal: (total, range) => {
      return `Showing ${range[0]}-${range[1]} of ${total} Action items`;
    },
  };

  const handleTitleCheckboxChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const totalPages = Math.ceil(pagination.total / pagination.pageSize);
      const itemsOnCurrentPage =
        pagination.current === totalPages
          ? pagination.total % pagination.pageSize || pagination.pageSize
          : pagination.pageSize;
      const currentPage = pagination.current;
      const currentPageNumbers = Array(itemsOnCurrentPage).fill(currentPage);
      const updatedPageList = [...pageList, ...currentPageNumbers];
      setPageList(updatedPageList);

      let allSelectedRows = Actions.filter(
        (record) => record.status !== "Deleted"
      );
      setSelectedRows((prev) => {
        const uniqueRowsSet = new Set([
          ...prev.map((row) => JSON.stringify(row)),
          ...allSelectedRows.map((row) => JSON.stringify(row)),
        ]);
        const uniqueRowsArray = Array.from(uniqueRowsSet).map((rowString) =>
          JSON.parse(rowString)
        );
        return uniqueRowsArray;
      });
    }
  };

  const handleCheckboxChange = (record, checked) => {
    if (selectAll) {
      if (checked) {
        const updatedPageList = [...pageList, pagination.current];
        setPageList(updatedPageList);
      } else {
        const indexToRemove = pageList.indexOf(pagination.current);
        if (indexToRemove !== -1) {
          const updatedPageList = [
            ...pageList.slice(0, indexToRemove),
            ...pageList.slice(indexToRemove + 1),
          ];
          setPageList(updatedPageList);
        }
      }
      const unselected = unselectedCustomers.some((row) => row === record.id);
      let updateUnselected = [];

      if (unselected) {
        updateUnselected = unselectedCustomers.filter(
          (row) => row !== record.id
        );
      } else {
        updateUnselected = [...unselectedCustomers, record.id];
      }
      setUnselectedCustomers(updateUnselected);
    } else {
      const isSelected = selectedRows.some((row) => row.id === record.id);
      let updatedSelectedRows = [];

      if (isSelected) {
        updatedSelectedRows = selectedRows.filter(
          (row) => row.id !== record.id
        );
      } else {
        updatedSelectedRows = [...selectedRows, record];
      }
      if (!isSelected) {
        // Append pagination.current to pageList if the row is selected
        const updatedPageList = [...pageList, pagination.current];
        setPageList(updatedPageList);
      } else {
        const indexToRemove = pageList.indexOf(pagination.current);
        if (indexToRemove !== -1) {
          // Remove pagination.current from pageList
          const updatedPageList = [
            ...pageList.slice(0, indexToRemove),
            ...pageList.slice(indexToRemove + 1),
          ];
          setPageList(updatedPageList);
        }
      }
      setSelectedRows(updatedSelectedRows);
    }
  };

  async function fetchAuditLogs() {
    setLoading(true);
    const payload = searchValues;
    try {
      const logs = await axios.post(
        `${config.backendHost}actions/search/?page=1&per_page=${pagination.pageSize}&order_by=${sort.order_by}&asc_or_desc=${sort.asc_or_desc}`,
        {
          display_id: payload.display_id,
          customer_id: cid ? cid : payload.customer_id,
          customer_id_action: payload.customer_id_action,
          customer_name: payload.customer_name,
          due_date: payload.due_date,
          action_desc: payload.action_desc,
          status_desc: payload.status_desc,
          name:payload.name,
          action_owner: payload.action_owner,
          status:  statusFilter,
          raised_at: payload.raised_at,
          raised_by: payload.raised_by,
          interaction_display_id: payload.interaction_display_id,
          interaction_id: payload.interaction_id
        },
        {
          headers: {
            authorization: token,
          },
        }
      );
      
      const actionID = logs.data.data.map((actions) => actions.id);
      setCurrentCustomers(actionID);
      setSelectedRows([]);
      setSelectAll(false);
      UserNameData(logs.data.user_name);
      setActions(logs.data.data);
      setPagination({
        ...pagination,
        current: logs.data.meta_data.page,
        pageSize: logs.data.meta_data.per_page,
        total: logs.data.meta_data.count,
      });
      setLoading(false);
      setIsFormSubmitting(false);
    } catch (e) {
      setLoading(false);
      console.log("Error fetching audit logs");
    }
  }

  async function fetchLogs(status) {
    setLoading(true);
    const payload = searchValues;
    try {
      const logs = await axios.post(
        `${config.backendHost}actions/search/?page=1&per_page=${pagination.pageSize}&order_by=${sort.order_by}&asc_or_desc=${sort.asc_or_desc}`,
        {
          display_id: payload.display_id,
          customer_id: cid ? cid : payload.customer_id,
          customer_id_action: payload.customer_id_action,
          customer_name: payload.customer_name,
          due_date: payload.due_date,
          action_desc: payload.action_desc,
          status_desc: payload.status_desc,
          name:payload.name,
          action_owner: payload.action_owner,
          status: status,
          raised_at: payload.raised_at,
          raised_by: payload.raised_by,
          interaction_display_id: payload.interaction_display_id,
          interaction_id: payload.interaction_id
        },
        {
          headers: {
            authorization: token,
          },
        }
      );
      
      const actionID = logs.data.data.map((actions) => actions.id);
      setCurrentCustomers(actionID);
      setSelectedRows([]);
      setSelectAll(false);
      UserNameData(logs.data.user_name);
      setActions(logs.data.data);
      setPagination({
        ...pagination,
        current: logs.data.meta_data.page,
        pageSize: logs.data.meta_data.per_page,
        total: logs.data.meta_data.count,
      });
      setLoading(false);
      setIsLoading(false);
      setIsFormSubmitting(false);
    } catch (e) {
      setLoading(false);
      console.log("Error fetching audit logs");
    }
  }
  useEffect(() => {
    if(loading){
      fetchLogs(statusFilter)
    }
    fetchAuditLogs();
  }, [searchValues, sort,loading]);

  return (
    <React.Fragment>
      <Card
        style={{ marginTop: "10px" }}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{fontSize: "20px" , fontWeight:"400" }}>Action Items</p>
            <div className="space-x-4" style={{marginRight:"0.8%"}}>
              <Button
                style={{
                  color: "#fff",
                  backgroundColor: "#1890ff",
                  width: "120px",
                }}
                onClick={() => setActionItemModal(true)}
              >
                Add
              </Button>
              <Button
                style={{
                  color: "#fff",
                  backgroundColor: selectedRows.length ? "#1890ff" : "#d9d9d9",
                  width: "120px",
                }}
                onClick={() => setDeleteWarningModal(true)}
                disabled={selectedRows.length === 0}
              >
                Delete
              </Button>
            </div>
          </div>
        }
      >
        <ModularTable
          tableData={Actions}
          style={{ innerHeight: "100%", padding: "0px 10px" }}
          Columnheadings={[
            {
              dataIndex: "vertical_bar",
              key: "vertical_bar",
              width: 5,
              title: <b className="table-header"></b>,
              render: (text, record) => (
                <div className="center-div">
                  <>
                    {searchValues.customer_name && record.exact === "Approx" ? (
                      <Tooltip title={record.match}>
                        <div className="vertical-orange"></div>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </>
                  <>
                    {searchValues.customer_name && record.exact === "Exact" ? (
                      <Tooltip title={record.match}>
                        <div className="vertical"></div>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </>
                </div>
              ),
              _visible: true,
              order: -999,
            },
            {
              dataIndex: "customer_id",
              key: "customer_id",
              title: <b className="table-header">CID</b>,
              sorter: true,
              align: "right",
              width: 50,
            },
            {
              dataIndex: "customer_name",
              key: "customer_name",
              title: <b className="table-header">Customer Name</b>,
              render: (text, record) => (
                <Link
                  target="_blank"
                  className="link-style"
                  to={`/view-customer/${record.customer_id}`}
                >
                  {text}
                </Link>
              ),
              sorter: true,
              align: "left",
              width: 200,
            },
            {
              dataIndex: "name",
              key: "name",
              title: <b className="table-header">Action Item Name</b>,
              render: (text, record) => (
                <>
                  {record.status !== "Deleted" ? (
                    <Link
                      className="link-style"
                      onClick={() => handleSelectedRecord(record)}
                    >
                      {text}
                    </Link>
                  ) : (
                    <span>{text}</span>
                  )}
                </>
              ),
              sorter: true,
              align: "left",
              width: 200,
            },
            {
              dataIndex: "action_desc",
              key: "action_desc",
              title: <b className="table-header">Action Item Description</b>,
              sorter: true,
              align: "left",
              width: 200,
            },

            {
              dataIndex: "status",
              key: "status",
              title: <b className="table-header">Status</b>,
              sorter: true,
              align: "left",
              filters: [
                {
                  text: "Open",
                  value: "Open",
                },
                {
                  text: "Delayed",
                  value: "Delayed",
                },
                {
                  text: "Deleted",
                  value: "Deleted",
                },
                {
                  text: "Closed",
                  value: "Closed",
                }
              ],
              width: 100,
              defaultFilteredValue: statusFilter,
              onFilter: (value, record) => record.status.indexOf(value) === 0,
              
              render: (text) => {
                let color;
                switch (text.toLowerCase()) {
                  case 'delayed':
                    color = 'red';
                    break;
                  case 'closed':
                    color = 'green';
                    break;
                  default:
                    color = 'black';
                }
                return <span style={{ color }}>{text}</span>;
              }
            },
            {
              dataIndex: "status_desc",
              key: "status_desc",
              title: <b className="table-header">Status Description</b>,
              sorter: true,
              align: "left",
              width: 200,
            },    
            {
              dataIndex: "action_owner",
              key: "action_owner",
              title: <b className="table-header">Action Owner</b>,
              sorter: true,
              align: "left",
              width: 200,
            },
            {
              dataIndex: "due_date",
              key: "due_date",
              title: <b className="table-header">Due Date</b>,
              render: (date) => ActionDate(date),
              sorter: true,
              align: "right",
              width: 100,
            },
            {
              dataIndex: "user_name",
              key: "user_name",
              title: <b className="table-header">Raised By</b>,
              sorter: true,
              align: "left",
              width: 200,
            },
            {
              dataIndex: "raised_at",
              key: "raised_at",
              title: <b className="table-header">Raised Date</b>,
              render: (date) => RaisedDate(date),
              sorter: true,
              align: "right",
              width: 100,
            },
            {
              dataIndex: "display_id",
              key: "display_id",
              title: <b className="table-header">AID</b>,
              sorter: true,
              align: "right",
              width: 100,
            },
            {
              dataIndex: "interaction_display_id",
              key: "interaction_display_id",
              title: <b className="table-header">IID</b>,
              sorter: true,
              align: "right",
              width: 100,
            },
            {
              dataIndex: "checkbox",
              key: "checkbox",
              title: (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Checkbox
                    checked={
                      selectAll && unselectedCustomers.length
                        ? !currentCustomers.some((id) =>
                            unselectedCustomers.includes(id)
                          )
                        : selectAll
                        ? true
                        : currentCustomers.every((id) =>
                            selectedRows.some((item) => item.id === id)
                          )
                    }
                    onChange={handleTitleCheckboxChange}
                  />
                  <p
                    style={{
                      color: "#1890ff",
                      position: "absolute",
                      bottom: -4,
                    }}
                  >
                    {selectAll
                      ? pagination.total - unselectedCustomers.length === 0
                        ? ""
                        : pagination.total - unselectedCustomers.length
                      : selectedRows.length === 0
                      ? ""
                      : selectedRows.length}
                  </p>
                </div>
              ),

              width: 60,
              render: (_, record) => (
                <Checkbox
                  checked={
                    selectAll
                      ? !unselectedCustomers.includes(record.id)
                      : selectedRows.some((row) => row.id === record.id)
                  }
                  onChange={(e) =>
                    handleCheckboxChange(record, e.target.checked)
                  }
                  disabled={record.status === "Deleted"}
                />
              ),
              align: "center",
              fixed: "right",
            },
          ]}
          loading={{
            spinning: Loading,
            indicator: <TableLoading className="loading-style" />,
          }}
          handleTableChange={handleTableChange}
          rowSelection={rowSelection}
          pagination={customPagination}
          rowClassName={(record) =>
            record.status === "Deleted" ? "custom-row-deleted" : ""
          }
          scroll={{ x: "max-content" }}
        />
      </Card>
      <Modal
        open={deleteWarningModal}
        title="Warning!!"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setDeleteWarningModal(false);
              setDeleteComments(true);
            }}
          >
            YES
          </Button>,
          <Button
            key="NO"
            onClick={() => {
              setDeleteWarningModal(false);
            }}
          >
            NO
          </Button>,
        ]}
        onCancel={() => {
          setDeleteWarningModal(false);
        }}
      >
        Are you sure you want to delete selected Action Items?
      </Modal>
      <Modal
  open={deleteComments}
  title="Delete Comments"
  footer={[
    <Button
      key="submit"
      type="primary"
      onClick={handleDeleteActionItem}
    >
      OK
    </Button>,
    <Button
      key="cancel"
      onClick={() => {
        deleteForm.resetFields(); // Reset form fields
        setDeleteComments(false);
      }}
    >
      Cancel
    </Button>,
  ]}
  onCancel={() => {
    deleteForm.resetFields(); // Reset form fields
    setDeleteComments(false);
  }}
>
  <Form
    colon={false}
    form={deleteForm}
    onFinish={handleDeleteActionItem}
  >
    <Form.Item name="delete_Message">
      <Input.TextArea
        rows={4}
        maxLength={50}
        onChange={(e) => setDeleteMessage(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter" && event.ctrlKey) {
            handleDeleteActionItem();
          }
        }}
      />
    </Form.Item>
  </Form>
</Modal>

    </React.Fragment>
  );
};
export default Actions;
