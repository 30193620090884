import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import "./style.scss";

import "antd/dist/antd.css";

/* antd icons */
import {
  CrownOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  TagOutlined,
  ContactsOutlined,
  AuditOutlined,
  ClusterOutlined,
} from "@ant-design/icons";

import { Layout, Menu } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const { Sider } = Layout;

const Sidebar = () => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const handleMenuClick = (e) => {
    if (e.key === selectedKey) {
      // window.location.reload();
      setSelectedKey(e.key);
    } else {
      setSelectedKey(e.key);
      // window.location.reload();
    }
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(
      "Customers",
      "customers",

      <CrownOutlined />,
      [
        getItem(
          "Customer Master Data",
          "/customers",
          <Link key="customers-link" to="/customers" />
        ),
        getItem(
          "Customer Interactions",
          "/customer-interactions",
          <a
            key="customer-interactions-link"
            onClick={(e) => {
              e.preventDefault(); 
              window.location.href = "/customer-interactions";
            }}
          />
        ),
        getItem(
          "Customer Action Items",
          "/customer-action-items",
          <a
          key="customer-action-items-link"
          onClick={(e) => {
            e.preventDefault(); 
            window.location.href = "/customer-action-items";
          }}
        />
        ),
      ]
    ),
    getItem(
      "Tenants",
      "/tenants",
      <Link key="tenants-link" to="/tenants">
        <HomeOutlined />
      </Link>
    ),
    getItem(
      "Tags",
      "tags",

      <TagOutlined />,
      [
        getItem(
          "Business Tags",
          "/business-tags",
          <Link key="business-tags-link" to="/business-tags" />
        ),
        getItem(
          "Data Mastering Tags",
          "/datamastering-tags",
          <Link key="datamastering-tags-link" to="/datamastering-tags" />
        ),
        getItem(
          "My Tags",
          "/my-tags",
          <Link key="my-tags-link" to="/my-tags" />
        ),
        // getItem(
        //   "Clustering Tags",
        //   "/clustering-tags",
        //   <Link key="clustering-tags-link" to="/clustering-tags" />
        // ),
      ]
    ),
    getItem(
      "Client Partners",
      "/client-partners",
      <Link key="client-partners-link" to="/client-partners">
        <ContactsOutlined />
      </Link>
    ),

    getItem("Carts", "carts", <ShoppingCartOutlined />, [
      getItem(
        "Customer Cart",
        "/customer-cart",
        <Link key="customer-cart-link" to="/customer-cart" />
      ),
      getItem(
        "Tenant Cart",
        "/tenant-cart",
        <Link key="tenant-cart-link" to="/tenant-cart" />
      ),
    ]),
    getItem("Audit logs", "audit", <AuditOutlined />, [
      getItem(
        "Customer Database Audit",
        "/customer-database-audit",
        <Link
          key="customer-database-audit-link"
          to="/customer-database-audit"
        />
      ),
      getItem(
        " Child Customer Audit",
        "/child-customer-audit",
        <Link key="child-customer-audit-link" to="/child-customer-audit" />
      ),
      getItem(
        "Customer Merge Audit",
        "/customer-merge-audit",
        <Link key="customer-merge-audit-link" to="/customer-merge-audit" />
      ),
      getItem(
        "Tenant Database Audit",
        "/tenant-database-audit",
        <Link key="tenant-database-audit-link" to="/tenant-database-audit" />
      ),
      getItem(
        "Mapping Audit",
        "/mapping-audit",
        <Link key="mapping-audit-link" to="/mapping-audit" />
      ),
    ]),
    // getItem("Clustering", "/clustering", <Link key="clustering-link" to="/clustering"><ClusterOutlined /></Link>),
  ];

  return (
    <Sider className="sider-style" collapsedWidth="50px" collapsed>
      <Menu
        className="menu-style"
        onClick={handleMenuClick}
        defaultSelectedKeys={[selectedKey]}
        theme="light"
        mode="inline"
        items={items}
      />
    </Sider>
  );
};

export default Sidebar;
