import React from "react";

/* PropTypes */
import PropTypes from "prop-types";

/* Styles Utils */
import Classnames from "classnames";

/* React Loader Spinner */
import { ThreeCircles, Oval, Bars, RotatingSquare } from "react-loader-spinner";

function LoadingWidget({
    type = "ThreeCircles",
    height = "100%",
    width = "100%",
    spinnerWidth = "100px",
    spinnerHeight = "100px",
    color = "#1890ff",
    className = "",
    ...props
}) {
    let Loader;

    switch (type) {
        case "Oval":
            Loader = Oval;
            break;
        case "Bars":
            Loader = Bars;
            break;
        case "RotatingSquare":
            Loader = RotatingSquare;
            break;
        case "ThreeCircles":
        default:
            Loader = ThreeCircles;
            break;
    }

    return (
        <div
            className={Classnames("flex items-center justify-center", className, {
                "h-screen": height === "screen",
                "h-full": height === "100%",
                "w-screen": width === "screen",
                "w-full": width === "100%",
            })}
        >
            <Loader
                color={color}
                height={spinnerHeight}
                width={spinnerWidth}
                ariaLabel="loading-rotating"
                {...props}
            />
        </div>
    );
}

LoadingWidget.propsType = {
    type: PropTypes.oneOf(["Oval", "Bars", "ThreeCircles", "RotatingSquare"])
        .isRequired,
    height: PropTypes.string,
    width: PropTypes.string,
    spinnerWidth: PropTypes.string,
    spinnerHeight: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
};

export default React.memo(LoadingWidget);