import React, { useState, useEffect, useRef } from "react";

import {
  Input,
  Select,
  notification,
  DatePicker,
  Modal,
  Form,
  Button,
  Space,
} from "antd";

import axios from "axios";

import config from "../../../config";

import { validateTwoChar } from "../../../utils/validation/validateTwoChar";

const ActionItemModal = ({
  actionItemModel,
  setActionItemModal,
  userName,
  recordData,
  id,
  setIsLoading
}) => {
  const [actionOwners, setActionOwners] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerIDList, setCustomerIDList] = useState([]);
  const [IIDList, setIIDList] = useState([]);
  const [selectedContext, setSelectedContext] = useState("");
  const [createActionForm] = Form.useForm();
  const token = localStorage.getItem("token");
  const formRef = useRef();
  const [InteractionData, setInteractionData] = useState([]);

  const layout = {
    labelCol: { span: 7 },
    labelAlign: "left",
    wrapperCol: { span: 20 },
    gutter: 24,
  };

  const ESTDate = () => {
    const localDate = new Date();

    // Get the current UTC time
    const utcTime = localDate.getTime() + localDate.getTimezoneOffset() * 60000;

    // Create a new date object with the offset for EST (UTC-5)
    const estOffset = -5 * 60 * 60 * 1000;
    const estDate = new Date(utcTime + estOffset);

    // Extract individual date and time components
    const year = estDate.getFullYear();
    const month = String(estDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(estDate.getDate()).padStart(2, "0");
    const hours = String(estDate.getHours()).padStart(2, "0");
    const minutes = String(estDate.getMinutes()).padStart(2, "0");
    const seconds = String(estDate.getSeconds()).padStart(2, "0");

    // Construct the formatted date and time string
    const estDateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return estDateTimeString;
  };


  useEffect(() => {
    createActionForm.setFieldsValue(recordData);
    axios
      .get(`${config.backendHost}interactions/users/`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setActionOwners(
          response.data?.map((users) => {
            return {
              value: users,
              label: users,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${config.backendHost}tenants/remap_dropdown/?order_by=name`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setCustomerList(
          response.data.data?.map((field) => {
            return {
              value: field.customer_id,
              label: field.customer_name,
            };
          })
        );
      });
    if (id) {
      Promise.all([
        axios
          .get(`${config.backendHost}interactions/${id}`, {
            headers: { authorization: token },
          })
          .then((response) => {
            setInteractionData(response.data.data);
          }),
      ]);
    }
    axios
      .get(`${config.backendHost}tenants/remap_dropdown/`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setCustomerIDList(
          response.data.data?.map((field) => {
            return {
              value: field.customer_id,
              label: field.customer_id,
            };
          })
        );
      });

    axios
      .get(`${config.backendHost}interactions/ids/`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setIIDList(
          response.data?.map((id) => {
            return {
              value: id.id,
              label: id.display_id,
              customer_name: id.customer_name,
              customer_id: id.customer_id,
            };
          })
        );
      });
  }, []);
  
  const handleCreateActions = () => {
    
    createActionForm
      .validateFields()
      .then(() => {
        return axios.post(
          `${config.backendHost}actions/`,
          {
            name: createActionForm.getFieldValue("name"),
            customer_id: createActionForm.getFieldValue("customer_id"),
            interaction_id: id ? id : createActionForm.getFieldValue("interaction_id"),
            due_date: createActionForm.getFieldValue("due_date"),
            prev_due_dates: createActionForm.getFieldValue("prev_due_dates"),
            action_desc: createActionForm.getFieldValue("action_desc"),
            status_desc: createActionForm.getFieldValue("status_desc"),
            action_owner: createActionForm.getFieldValue("action_owner"),
            status: createActionForm.getFieldValue("status"),
          },
          {
            headers: {
              authorization: token,
            },
          }
        );
      })
      .then((response) => {
        setActionItemModal(false);
        createActionForm.resetFields();
        notification.open({
          message: `Success`,
          description: response.data,
          className: "custom-class",
          style: {
            width: 400,
          },
        });
        // if(id!== undefined){
        //   // localStorage.setItem('collapsable', true)
        // }
        setIsLoading(true)
      // setTimeout(() => {
      //     window.location.reload();
      //   }, 1000);  
      })
      .catch((errorInfo) => {
        console.log("validation failed" + errorInfo);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.ctrlKey) {
      handleCreateActions();
    }
  };

  const statusList = [
    { value: "Open", label: "Open" },
    { value: "Delayed", label: "Delayed" },
    { value: "Closed", label: "Closed" },
  ];

  return (
    <React.Fragment>
      <Modal
        open={actionItemModel}
        title="Create Action Item"
        width={1000}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setActionItemModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleCreateActions}>
            OK
          </Button>,
        ]}
        onCancel={() => {
          setActionItemModal(false);
        }}
      >
        <Form
          ref={formRef}
          colon={false}
          form={createActionForm}
          {...layout}
          onFinish={handleCreateActions}
          onKeyDown={handleKeyDown}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "24px",
            }}
          >
            <div>
              {id === undefined ? (
                <Form.Item name="interaction_id" label="Interaction ID">
                  <Select
                    showArrow
                    allowClear
                    showSearch
                    placeholder="Select Interaction ID"
                    style={{ width: "100%" }}
                    onChange={(value, options) => {
                      createActionForm.setFieldValue("interaction_id", value);
                      createActionForm.setFieldValue(
                        "customer_id",
                        options.customer_id ? options.customer_id : undefined
                      );
                      createActionForm.setFieldValue(
                        "customer_name",
                        options.customer_name ? options.customer_id : undefined
                      );
                      setSelectedContext(true);
                    }}
                    onClear={() => {
                      createActionForm.setFieldValue("customer_id", "");
                      createActionForm.setFieldValue("customer_name", "");
                      setSelectedContext(false);
                    }}
                    options={IIDList}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="interaction_id"
                  label="Interaction ID"
                  initialValue={
                    InteractionData ? InteractionData.display_id : ""
                  }
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item>
              )}

              {id === undefined ? (
                <Form.Item
                  name="customer_id"
                  rules={[
                    {
                      required: true,
                      message: "Please enter CID!",
                    },
                  ]}
                  label="CID"
                >
                  <Select
                    showArrow
                    allowClear
                    showSearch
                    placeholder="Select Customer ID"
                    style={{ width: "100%" }}
                    onChange={(value, options) => {
                      createActionForm.setFieldValue("customer_name", value);
                    }}
                    options={customerIDList}
                    disabled={selectedContext}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="customer_id"
                  rules={[
                    {
                      required: true,
                      message: "Please enter CID!",
                    },
                  ]}
                  label="CID"
                  initialValue={
                    InteractionData ? InteractionData.customer_id : ""
                  }
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item>
              )}

              {id === undefined ? (
                <Form.Item
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Customer Name!",
                    },
                  ]}
                  label="Customer Name"
                >
                  <Select
                    showArrow
                    allowClear
                    showSearch
                    placeholder="Select Customer Name"                   
                    style={{ width: "100%" }}
                    onChange={(value, options) => {
                      createActionForm.setFieldValue("customer_id", value);
                    }}
                    options={customerList}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    disabled={selectedContext}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Customer Name!",
                    },
                  ]}
                  label="Customer Name"
                  initialValue={
                    InteractionData ? InteractionData.customer_name : ""
                  }
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item>
              )}

              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Action Item Name!",
                  },
                  { validator: validateTwoChar },
                ]}
                label="Action Item Name"
              >
                <Input
                  type="text"
                  placeholder="Please enter Action Item Name"
                  onChange={(e) =>
                    createActionForm.setFieldValue("name", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                name="action_owner"
                rules={[
                  {
                    required: true,
                    message: "Please enter action owner!",
                  },
                ]}
                label="Action Owner"
              >
                <Select
                  showArrow
                  allowClear
                  showSearch
                  placeholder="Select Action Owner"
                  style={{ width: "100%" }}
                  onChange={(value, options) => {
                    createActionForm.setFieldValue("action_owner", value);
                  }}
                  options={actionOwners}
                />
              </Form.Item>
              <Form.Item
                name="due_date"
                rules={[
                  {
                    required: true,
                    message: "Please enter Due Date!",
                  },
                ]}
                label="Due Date"
              >
                <Space direction="vertical">
                  <DatePicker
                    format={"MM-DD-YYYY"}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      createActionForm.setFieldValue("due_date", dateString);
                      createActionForm.setFieldValue(
                        "prev_due_dates",
                        dateString + " [" + userName + " " + ESTDate() + "]"
                      );
                    }}
                  />
                </Space>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="action_desc"
                rules={[
                  {
                    required: true,
                    message: "Please enter Action Item Description!",
                  },
                  { validator: validateTwoChar },
                ]}
                label="Action Item Desc"
              >
                <Input.TextArea
                  type="text"
                  placeholder="Please enter Action Item Description"
                  onChange={(e) =>
                    createActionForm.setFieldValue(
                      "action_desc",
                      e.target.value
                    )
                  }
                  style={{ minHeight: 115 }}
                />
              </Form.Item>
              <Form.Item name="status" label="Status">
                <Select
                  showArrow
                  showSearch
                  defaultValue={"Open"}
                  style={{ width: "100%" }}
                  onChange={(value, options) => {
                    console.log(value);
                    createActionForm.setFieldValue("status", value);
                  }}
                  options={statusList}
                />
              </Form.Item>
              <Form.Item name="status_desc" label="Status Desc">
                <Input.TextArea
                  type="text"
                  placeholder="Please enter Status Description"
                  onChange={(e) =>
                    createActionForm.setFieldValue(
                      "status_desc",
                      e.target.value
                    )
                  }
                  style={{ minHeight: 115 }}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ActionItemModal;
