import React, { useState, useEffect } from "react";

/* React Router */
import { Link } from "react-router-dom";

/* assets */
import prodLogo from "../../../../assets/svg/logo-title-prod.svg";
import stageLogo from "../../../../assets/svg/logo-title-stage.svg";
import testLogo from "../../../../assets/svg/logo-title-test.svg";

import { Layout, Dropdown, Avatar, Space } from "antd";
import { UserOutlined, LogoutOutlined, DownOutlined } from "@ant-design/icons";
import "./style.scss";

const { Header } = Layout;

const Navbar = () => {
  const [env, setEnv] = useState(null);

  const url = window.location.hostname;
  const urlSegments = url.split(".");
  const envVariable = urlSegments[0];

  useEffect(() => {
    if (envVariable === "staging") {
      setEnv("STAGING");
    } else if (envVariable === "mdm") {
      setEnv("PROD");
    } else if (envVariable === "localhost" || 127) {
      setEnv("TESTING");
    } else {
      setEnv("PROD");
    }
  }, [envVariable]);

  const items = [
    {
      label: "Logout",
      key: "item-1",
      icon: <LogoutOutlined />,
      onClick: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/";
      },
    },
  ];

  const token = localStorage.getItem("token");
  const decode = JSON.parse(atob(token.split(".")[1]));

  return (
    <Header className={`my-header-${env}`}>
      <div className="logo-container">
        <div>
          <Link to={"/customers"}>
            {process.env.REACT_APP_ENV === "production" ? (
              <img src={prodLogo} alt="Reach24-logo" />
            ) : process.env.REACT_APP_ENV === "staging" ? (
              <img src={stageLogo} alt="Reach24-logo" />
            ) : (
              <img src={testLogo} alt="Reach24-logo" />
            )}
          </Link>
        </div>
      </div>

      <div className="profile">
        <Avatar size="small" icon={<UserOutlined />} />
        <span className="username">{decode.email}</span>
        <Dropdown className="dropdown" arrow menu={{ items }} style={{ padding: "0px" }}>
          <a className="ant-dropdown-link" href="/customers" onClick={(e) => e.preventDefault()}>
            <Space>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;
